// eslint-disable-next-line import/named
import { Module } from 'vuex'
import { Record } from '~/lib/Record'

interface State {
  incompleted: Record[]
}

const store: Module<State, State> = {
  state() {
    return {
      incompleted: [],
    }
  },
  getters: {
    incompletedRecords: (state) => {
      return state.incompleted
    },
  },
  mutations: {
    updateIncompleted(state: State, incompleted: Record[]) {
      state.incompleted = incompleted
    }
  },
  actions: {
    async load({ commit }) {
      const resp = await this.$axios.$get('/records/incomplete/minor')
      const incompleted = resp.data.records.map((r: any) => new Record(r)).filter((r: Record) => r.timesheet.id)
      commit('updateIncompleted', incompleted)
    },
  }
}

export default store
