export function isIosVersionAtLeast(major: number, minor: number): boolean {
  const userAgent: string = navigator.userAgent;
  const matches: RegExpMatchArray | null = userAgent.match(/CPU iP(?:hone|ad|od) OS (\d+)_(\d+)/i);
  if (matches) {
    const detectedMajor: number = parseInt(matches[1], 10);
    const detectedMinor: number = parseInt(matches[2], 10);

    if (detectedMajor > major || (detectedMajor === major && detectedMinor >= minor)) {
      return true;
    }
  }

  return false;
}
