import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1820184f = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4e787538 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fcd98f64 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _5f364a34 = () => interopDefault(import('../pages/sample.vue' /* webpackChunkName: "pages/sample" */))
const _3a19ae4e = () => interopDefault(import('../pages/timesheets/index.vue' /* webpackChunkName: "pages/timesheets/index" */))
const _773c144c = () => interopDefault(import('../pages/timesheets/_date/index.vue' /* webpackChunkName: "pages/timesheets/_date/index" */))
const _b9ca8c98 = () => interopDefault(import('../pages/timesheets/_date/_id.vue' /* webpackChunkName: "pages/timesheets/_date/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _1820184f,
    name: "login___en"
  }, {
    path: "/mm",
    component: _4e787538,
    name: "index___mm"
  }, {
    path: "/notifications",
    component: _fcd98f64,
    name: "notifications___en"
  }, {
    path: "/sample",
    component: _5f364a34,
    name: "sample___en"
  }, {
    path: "/th",
    component: _4e787538,
    name: "index___th"
  }, {
    path: "/timesheets",
    component: _3a19ae4e,
    name: "timesheets___en"
  }, {
    path: "/mm/login",
    component: _1820184f,
    name: "login___mm"
  }, {
    path: "/mm/notifications",
    component: _fcd98f64,
    name: "notifications___mm"
  }, {
    path: "/mm/sample",
    component: _5f364a34,
    name: "sample___mm"
  }, {
    path: "/mm/timesheets",
    component: _3a19ae4e,
    name: "timesheets___mm"
  }, {
    path: "/th/login",
    component: _1820184f,
    name: "login___th"
  }, {
    path: "/th/notifications",
    component: _fcd98f64,
    name: "notifications___th"
  }, {
    path: "/th/sample",
    component: _5f364a34,
    name: "sample___th"
  }, {
    path: "/th/timesheets",
    component: _3a19ae4e,
    name: "timesheets___th"
  }, {
    path: "/mm/timesheets/:date",
    component: _773c144c,
    name: "timesheets-date___mm"
  }, {
    path: "/th/timesheets/:date",
    component: _773c144c,
    name: "timesheets-date___th"
  }, {
    path: "/mm/timesheets/:date/:id",
    component: _b9ca8c98,
    name: "timesheets-date-id___mm"
  }, {
    path: "/th/timesheets/:date/:id",
    component: _b9ca8c98,
    name: "timesheets-date-id___th"
  }, {
    path: "/timesheets/:date",
    component: _773c144c,
    name: "timesheets-date___en"
  }, {
    path: "/timesheets/:date/:id",
    component: _b9ca8c98,
    name: "timesheets-date-id___en"
  }, {
    path: "/",
    component: _4e787538,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
