import {
  // eslint-disable-next-line import/named
  Module
} from 'vuex'
import { handleError, handleErrorMessage } from '~/utils/error'
import { Record } from '~/lib/Record'

export type LeaveType = {
  id: number,
  order: number,
  name: string,
}

export type Leave = {
  id: number,
  // eslint-disable-next-line camelcase
  leave_type: LeaveType,
}

type State = {
  selectedTypeId: number,
  leaveTypes: LeaveType[],
  date: string,
  errorMessage: string,
  editing: boolean,
  leaves: Leave[],
  record: Record|null,
}

export const leaveStore: Module<State, State> = {
  state() {
    return {
      selectedTypeId: 0,
      leaveTypes: [],
      date: '',
      errorMessage: '',
      editing: false,
      leaves: [],
      record: null,
    }
  },
  mutations: {
    selected(state, typeId) {
      state.selectedTypeId = typeId
    },
    updateLeaveTypes(state, leaveTypes) {
      state.leaveTypes = leaveTypes
    },
    updateErrorMessage(state, message) {
      state.errorMessage = message
    },
    updateDate(state, date) {
      state.date = date
    },
    updateEditing(state, editing) {
      state.editing = editing
    },
    updateLeaves(state, leaves) {
      state.leaves = leaves
    },
    updateLeaveRecord(state, record) {
      state.record = record
    },
  },
  getters: {
    selectedTypeId: state => {
      return state.selectedTypeId
    },
    leaveTypes: state => {
      return state.leaveTypes
    },
    errorMessage: state => state.errorMessage,
    editing: state => state.editing,
    leaves: state => state.leaves,
    record: state => state.record,
  },
  actions: {
    onSelectLeaveType({ commit }, leaveType: number) {
      commit('selected', leaveType)
    },
    async createLeave({ state, commit, dispatch }) {
      if (!state.leaveTypes.map(t => t.id).includes(state.selectedTypeId)) {
        commit('updateErrorMessage', 'Please select reason')
        return
      }
      try {
        let resp
        if (state.leaves.length > 0 && state.record) {
          resp = await this.$axios.$put(`/records/${state.record.id}/leaves`, {
            'leave_type_id': state.selectedTypeId
          })
        } else {
          resp = await this.$axios.$post('/records/leaves', {
            date: state.date,
            'leave_type_id': state.selectedTypeId
          })
        }

        const record = new Record(resp.data)

        commit('updateLeaveRecord', record)
        commit('updateLeaves', [record.leave])
        dispatch('finishEditing')

        return record
      } catch (e) {
        commit('updateErrorMessage', handleErrorMessage(e))
      }
    },
    async fetchLeaveTypes({ commit }) {
      try {
        const res = await this.$axios.$get('/leave_types')
        const leaveTypes = res.data.leave_types as LeaveType[]
        commit('updateLeaveTypes', leaveTypes)
      } catch (e) {
        commit('updateErrorMessage', handleErrorMessage(e))
      }
    },
    startEditing({ commit }, leaves: Leave[]) {
      commit('updateLeaveRecord', null)

      commit('updateEditing', true)
      commit('updateLeaves', leaves)
      if (leaves.length > 0) {
        commit('selected', leaves[0].leave_type.id)
      }
    },
    finishEditing({ commit }) {
      commit('updateErrorMessage', '')
      commit('updateEditing', false)
      commit('selected', 0)
    },
    async deleteLeave({ commit }, record: Record) {
      try {
        const res = await this.$axios.$delete(`/records/${record.id}/leaves`)
        commit('updateLeaves', [])
        return res.data
      } catch (e) {
        handleError(e)
      }
    }
  }
}

export default leaveStore
