import { NuxtAxiosInstance } from '@nuxtjs/axios'

// eslint-disable-next-line import/no-mutable-exports
let $axios: NuxtAxiosInstance
export function initializeAxios(axiosInstance: NuxtAxiosInstance) {
  axiosInstance.setHeader('X-OS-NAME', 'LITE')
  $axios = axiosInstance
}

export { $axios }
