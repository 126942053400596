import * as fns from 'date-fns'
import { ITimesheet, Odometer, Timesheet } from '~/lib/Timesheet'
import { Approval, IApproval } from '~/lib/Approval'

export class Record {
  public timesheet: ITimesheet
  private _data: any

  constructor(data: any) {
    this._data = data
    this.timesheet = new Timesheet(data.timesheet ?? {}) as ITimesheet
  }

  get id() {
    return this._data.id
  }

  get date(): Date {
    return new Date(this._data.date)
  }

  get dateString(): string {
    return this._data.date
  }

  get dateByDate(): Date {
    return fns.parse(`${this.dateString}`, 'yyyy-MM-dd', new Date())
  }

  get customFieldGroups(): any[] {
    return this._data.custom_field_groups
  }

  get approvals(): IApproval[] {
    if (!this._data.approvals) {
      return []
    }

    return this._data.approvals.filter ((a: any) => a.status !== 'approved')
      .map((a: any) => {
      return new Approval(a)
    })
  }

  get pendingApprovals(): IApproval[] {
    return this.approvals.filter ((a: any) => a.status === 'pending')
  }

  get displayDate(): string {
    return fns.format(this.date, 'dd/MM/yyyy')
  }

  get leave() {
    return this._data.leave
  }

  get odmeterAtFinished() {
    if (!this._data.odometers) {
      return
    }

    let distance
    for (const odometer of this._data.odometers) {
      if (odometer.type === 'work_finish') {
        distance = odometer.distance
      }
    }
    return distance
  }

  get odometers() {
    return this._data.odometers || []
  }

  get odmeterAtStarted() {
    if (!this._data.odometers) {
      return
    }

    let distance
    for (const odometer of this._data.odometers) {
      if (odometer.type === 'work_start') {
        distance = odometer.distance
      }
    }
    return distance
  }

  addOdometer(odometer: Odometer): void {
    this._data.odometers.push(odometer)
  }

  updateOdometer(odometer: Odometer): void {
    this._data.odometers = this._data.odometers.map((odo: Odometer) => {
      if (odo.id === odometer.id) return odometer
      return odo
    })
  }

}
