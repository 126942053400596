import * as fns from "date-fns"

export type Odometer = {
  id: number,
  distance: number,
  type: string,
  // eslint-disable-next-line camelcase
  actioned_at: number
}

type Employee = {
  // eslint-disable-next-line camelcase
  first_name: string
  // eslint-disable-next-line camelcase
  last_name: string
}

export interface ITimesheet {
  id: string|null
  date: string
  displayDate: string
  startedAt: string
  startedAtTime: number
  finishedAt: string
  finishedAtTime: number
  isHoliday: boolean
  status: string,
  employeeName: string,
  startedAtDate: Date|null,
  finishedAtDate: Date|null,
}

export class Timesheet implements ITimesheet {
  public static NULL_TIMELABEL = '-- : --'

  private _data: any

  constructor(data: any) {
    this._data = data
  }

  get date(): string {
    const result = this._data.date
    return result
  }

  get id() {
    return this._data.id
  }

  get startedAtTime(): number {
    return this._data.started_at
  }

  get startedAt(): string {
    if (!this._data.started_at || this.isHoliday) {
      return Timesheet.NULL_TIMELABEL
    }
    const time = this._data.started_at
    return this.timestamp(time)
  }

  get startedAtDate(): Date|null {
    if (!this._data.started_at) {
      return null
    }
    const time = this._data.started_at
    return new Date(time)
  }

  get finishedAtTime(): number {
    return this._data.finished_at
  }

  get finishedAt(): string {
    if (!this._data.finished_at || this.isHoliday) {
      return Timesheet.NULL_TIMELABEL
    }
    const time = this._data.finished_at
    const isOver = fns.getDay(new Date(time)) > fns.getDay(new Date(this.date))
    const hours = isOver ? new Date(time).getHours() + 24  : new Date(time).getHours()
    return fns.format(time, `${
      hours < 10 ? '0'+hours: hours
    }:mm`)
  }

  get finishedAtDate(): Date|null {
    if (!this._data.finished_at) {
      return null
    }
    const time = this._data.finished_at
    return new Date(time)
  }

  get isHoliday(): boolean {
    return this._data.is_holiday ?? false
  }

  get customField(): any {
    return this._data.custom_field
  }

  get status() {
    return this._data.status || ''
  }

  get displayDate() {
    return fns.format(Date.parse(this.date), 'dd/MM/yyyy')
  }

  get employeeName(): string {
    const employee = this._data.approver as Employee
    if (!employee) {
      return ''
    }

    return employee.first_name + employee.last_name
  }

  private timestamp(time: number): string {
    if (Number.isInteger(time)) {
      return fns.format(time as number, 'HH:mm')
    }

    return Timesheet.NULL_TIMELABEL
  }
}
