import liff from '@line/liff'

export function close(message: string | null = null) {
  if (!liff.isInClient()) {
    // eslint-disable-next-line no-console
    console.log(`LIFF: closeWindow()`)
  }

  if (message) {
    say(message, true)
    return
  }

  liff.closeWindow()
}

export function say(message: string, close: boolean = false) {
  if (!liff.isInClient()) {
    // eslint-disable-next-line no-console
    console.log(`LINE: ${message}`)
  }

  liff.sendMessages([
    {
      'type': 'text',
      'text': message
    }
  ]).finally(() => {
    if (close) {
      liff.closeWindow()
    }
  }).catch((err) => {
    liff.closeWindow()
  })
}
