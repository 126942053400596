import * as fns from 'date-fns'
// eslint-disable-next-line import/named
import { Module } from 'vuex'
import { handleError } from '~/utils/error'
import { close, say } from '~/utils/liff'
import { ITimesheet, Timesheet } from '~/lib/Timesheet'
import { $axios } from '~/utils/api'
import { Record } from '~/lib/Record'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { isIosVersionAtLeast } from '~/utils/bworser'

export enum WorkStatus {
  before,
  working,
  finished
}

interface State {
  status: WorkStatus,
  startfinish: 'start' | 'finish',
  approvalRequired: boolean,
  isUpdating: boolean,
  customFieldStructures: any,
  record: Record,
}

export const workingStore: Module<State, State> = {
  state() {
    return {
      status: WorkStatus.finished,
      startfinish: 'finish',
      approvalRequired: false,
      isUpdating: false,
      customFieldStructures: [],
      record: new Record({}),
    }
  },
  mutations: {
    changeStatus(state: State, status: WorkStatus) {
      state.status = status
    },
    changeStartfinish(state: State, startfinish: 'start' | 'finish') {
      state.startfinish = startfinish
    },
    changeApprovalRequired(state: State, approvalRequired: boolean) {
      state.approvalRequired = approvalRequired
    },
    updatingStarted(state: State) {
      state.isUpdating = true
    },
    updatingEnd(state: State) {
      state.isUpdating = false
    },
    updateCustomField(state: State, structures: any) {
      state.isUpdating = false
      state.customFieldStructures = structures
    },
    setRecord: (state: State, value: Record) => {
      state.record = value
    },
  },
  getters: {
    actionColor(state: State): string {
      switch (state.status) {
        case (WorkStatus.before):
          return 'start'
        case (WorkStatus.working):
          return 'finish'
        case (WorkStatus.finished):
          return 'error'
        default:
          return 'error'
      }
    },
    isWorking(state: State): boolean {
      return state.status === WorkStatus.working
    },
    dateForAPI(state: State): string {
      return state.record.timesheet.date
      // return fns.format(new Date(), 'yyyy-MM-dd')
    },
    timesheet(state: State): ITimesheet {
      return state.record.timesheet
    },
    record(state: State): Record {
      return state.record
    }
  },
  actions: {
    async start({ commit }, { distance, date, passcode, success }) {
      commit('updatingStarted')

      const timestamp = date ? date.getTime() : (new Date).getTime()

      async function sendWorkStart(axios: NuxtAxiosInstance, latitude?: number, longitude?: number) : Promise<Record> {
        const path = passcode ? '/me/work_start/approve' : '/me/work_start/lite'
        const params = {
          'stamp_time': timestamp,
          approval_passcode: passcode,
          latitude, longitude
        }
        if (distance > 0) {
          // @ts-ignore
          params.odometer_distance = distance
        }
        const resp = await axios.$post(path, params)

        const record = new Record(resp.data.record)
        commit('setRecord', record)
        if (record.timesheet) {
          commit('timesheet', new Timesheet(record.timesheet))
        }
        commit('changeStatus', WorkStatus.working)

        const checkoutCfgs = record.customFieldGroups.filter((g: any) => g.timing === 'checkin')
        if (checkoutCfgs.length > 0) {
          say(`🚙 ${fns.format(timestamp, 'HH:mm')} IN`)
          commit('updateCustomField', checkoutCfgs)
        } else {
          close(`🚙 ${fns.format(timestamp, 'HH:mm')} IN`)
        }

        return record
      }

      if (isIosVersionAtLeast(16, 4)) {
        // workaround of https://developers.line.biz/ja/news/2023/03/30/liff-mini-outage/
        try {
          const record = await sendWorkStart(this.$axios);
          success(record)
        } catch (error) {
          handleError(error)
          commit('changeStatus', WorkStatus.before)
        } finally {
          commit('updatingEnd')
        }
        return;
      }

      navigator.geolocation.getCurrentPosition(async position => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude
        try {
          const record = await sendWorkStart(this.$axios, latitude, longitude);
          success(record)
        } catch (error) {
          handleError(error)
          commit('changeStatus', WorkStatus.before)
        } finally {
          commit('updatingEnd')
        }
      }, () => {
        handleError('location is invalid')
        commit('changeStatus', WorkStatus.before)
        commit('updatingEnd')
      }, { enableHighAccuracy: true })
    },
    async finish({ commit }, { distance, date, passcode, success }) {
      commit('updatingStarted')

      const timestamp = date ? date.getTime() : (new Date).getTime()

      async function sendWorkFinish(axios: NuxtAxiosInstance, latitude?: number, longitude?: number) : Promise<Record> {
        const path = passcode ? '/me/work_finish/approve' : '/me/work_finish/lite'
        const params = {
          'stamp_time': timestamp,
          approval_passcode: passcode,
          latitude, longitude
        }
        if (distance > 0) {
          // @ts-ignore
          params.odometer_distance = distance
        }
        const resp = await axios.$post(path, params)
        commit('changeStatus', WorkStatus.before)

        const record = new Record(resp.data.record)
        commit('setRecord', record)
        if (resp.data.timesheet) {
          commit('timesheet', new Timesheet(record.timesheet))
        }

        const checkoutCfgs = record.customFieldGroups.filter((g: any) => g.timing === 'checkout')
        if (checkoutCfgs.length > 0) {
          commit('updateCustomField', checkoutCfgs)
          say(`🚗 ${fns.format(timestamp, 'HH:mm')} OUT`)
        } else {
          close(`🚗 ${fns.format(timestamp, 'HH:mm')} OUT`)
        }

        return record
      }

      if (isIosVersionAtLeast(16, 4)) {
        // workaround of https://developers.line.biz/ja/news/2023/03/30/liff-mini-outage/
        try {
          const record = await sendWorkFinish(this.$axios);
          success(record)
        } catch (error) {
          handleError(error)
          commit('changeStatus', WorkStatus.before)
        } finally {
          commit('updatingEnd')
        }
        return;
      }

      navigator.geolocation.getCurrentPosition(async position => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude
        try {
          const record = await sendWorkFinish(this.$axios, latitude, longitude);
          success(record)
        } catch (error) {
          handleError(error)
          commit('changeStatus', WorkStatus.working)
        } finally {
          commit('updatingEnd')
        }
      }, () => {
        handleError('location is invalid')
        commit('changeStatus', WorkStatus.before)
        commit('updatingEnd')
      }, { enableHighAccuracy: true })
    },
    async fetchStatus({ commit }) {
      try {
        const resp = await this.$axios.$get('/me/status')
        const status: string = resp.data.status
        const parsedStatus = parseStatus(status)
        commit('changeStatus', parsedStatus)
        commit('changeStartfinish', parsedStatus === WorkStatus.working ? 'finish' : 'start')
        commit('changeApprovalRequired', status.includes('approval_required'))
        if (resp.data.timesheet) {
          commit('timesheet', new Timesheet(resp.data.timesheet))
        }
      } catch (error) {
        handleError(error)
      }
    },
    async workingSkip({ commit }) {
      try {
        const resp = await this.$axios.$post('/me/work_skip', { 'stamp_time': Date.now() })
        const status: string = resp.data.status
        commit('changeStatus', parseStatus(status))
        commit('changeApprovalRequired', status.includes('approval_required'))
      } catch (error) {
        handleError(error)
      }
    },
    async fetchTimesheets({ commit }) {
      try {
        const date = new Date()
        const res = await $axios.$get('/records/records', {
          params: {
            date_from: fns.format(date, 'yyyy-MM-dd'),
            date_to: fns.format(date, 'yyyy-MM-dd')
          }
        })
        res.data.timesheets.forEach((data: any) => {
          if (data.id) {
            commit('timesheet', new Timesheet(data))
          }
        })
      } catch (error) {
        handleError(error)
      }
    }
  }
}

function parseStatus(status: string): WorkStatus {
  if (status.includes('before')) {
    return WorkStatus.before
  } else if (status.includes('working')) {
    return WorkStatus.working
  } else if (status.includes('finish')) {
    return WorkStatus.finished
  } else {
    return WorkStatus.before // default
  }
}

export default workingStore
