import Vue from 'vue'
import merge from '~lodash.mergewith'
import * as Sentry from '~@sentry/vue'

import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    Vue,
    release:"456afb8786af48adfad5841fdc444272195c0737",
    dsn:"https:\u002F\u002F2e46d74e411442a987162a7778690120@o308932.ingest.sentry.io\u002F5202806",
    environment:"production",
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
