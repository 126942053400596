interface APIError {
  detail: string
}

export function handleError(error: any) {
  alert(handleErrorMessage(error))
}

export function handleErrorMessage(error: any) {
  if (!error.response || !error.response.data) {
    return error
  }

  if (error.response.data.errors) {
    return error.response.data.errors.map((e: APIError) => e.detail).join('\n')
  }

  return error
}
