
  import Vue from 'vue'

  export default Vue.extend({
    computed: {
      appBarText() {
        const { user } = this.$auth
        // eslint-disable-next-line camelcase
        if (!user?.first_name) return 'Flare Dash'

        return `${user.first_name} ${user.last_name}`
      },
    },
  })
