export type IApproval = {
  id: number,
  timesheetId: number,
  date: string,
  status: string,
  startedAt: Date,
  finishedAt: Date,
  reasonOfStart: string,
  reasonOfFinish: string,
  reasonIdOfStart: string
  reasonIdOfFinish: string
  reasonDetailOfStart: string
  reasonDetailOfFinish: string
}

export class Approval implements IApproval {
  private props: any

  constructor(props: any) {
    this.props = props
  }

  get id() {
    return this.props.id
  }

  get timesheetId() {
    return this.props.timesheet_id
  }

  get date() {
    return this.props.date
  }

  get status() {
    return this.props.status
  }

  get startedAt() {
    return new Date(this.props.started_at)
  }

  get finishedAt() {
    return new Date(this.props.finished_at)
  }

  get reasonOfStart() {
    return this.props.start_approval_reason?.name
  }

  get reasonOfFinish() {
    return this.props.finish_approval_reason?.name
  }

  get reasonIdOfStart() {
    return this.props.start_approval_reason?.id
  }

  get reasonIdOfFinish() {
    return this.props.finish_approval_reason?.id
  }

  get reasonDetailOfStart() {
    return this.props.start_approval_reason_detail
  }

  get reasonDetailOfFinish() {
    return this.props.finish_approval_reason_detail
  }
}
