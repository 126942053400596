// eslint-disable-next-line import/named
import { Module } from 'vuex'
import * as fns from 'date-fns'
import { handleError, handleErrorMessage } from '~/utils/error'
import { ITimesheet, Timesheet } from '~/lib/Timesheet'
import { Record } from '~/lib/Record'
import { $axios } from '~/utils/api'

type State = {
  date: Date,
  timesheet: ITimesheet,
}

export const manualStore: Module<State, State> = {
  state: {
    date: new Date(),
    timesheet: new Timesheet({})
  },
  getters: {
    date: (state) => (state.date)
  },
  mutations: {
    updateDate(state: State, date: Date) {
      state.date = date
    },
    timesheet(state: State, timesheet: ITimesheet) {
      state.timesheet = timesheet
    }
  },
  actions: {
    prev({ getters, commit }) {
      const { date } = getters
      commit('updateDate', fns.subDays(date, 1))
    },
    next({ getters, commit }) {
      const { date } = getters
      commit('updateDate', fns.addDays(date, 1))
    },
    apply({ commit, getters }, payload: { hours: number, minutes: number }) {
      const { date } = getters
      let updated = fns.setHours(date, payload.hours)
      updated = fns.setMinutes(updated, payload.minutes)
      commit('updateDate', updated)
    },
    async updateOdometer(_, options: {
      record: Record, distance: number, type: string,
    }) {
      const {record, type, distance} = options
      const workType = type === 'start' ? 'work_start' : 'work_finish'
      try {
        await $axios.put(`/records/${record.id}/odometers`, {
          type: workType, distance
        })
      } catch (error) {
        handleError(error)
      }
    },
    async submit({ getters }, options: {
      passcode: string,
      type: string,
      record: Record,
      distance: number,
    }) {
      try {
        const { record } = options
        const { date } = getters
        const dayByDate = record.date.getTime()
          ? fns.format(record.date, 'yyyy-MM-dd')
          : fns.format(date, 'yyyy-MM-dd')
        const data = {
          date: dayByDate,
          is_holiday: false,
          is_over_night: false,
          approval_passcode: options.passcode
        } as any
        if (options.type === 'finish') {
          data.started_at = record.timesheet?.startedAtTime
          data.finished_at = fns.getUnixTime(date) * 1000
        } else {
          data.started_at = fns.getUnixTime(date) * 1000
        }

        if (options.distance > 0) {
          data.odometer_distance = options.distance
        }

        let resp: any
        if (record.id) {
          resp = await this.$axios.$put(`/records/${record.id}/timesheets/edit`, data)
        } else {
          resp = await this.$axios.$post('/records/timesheets', data)
        }

        return new Record(resp.data)
      } catch (e) {
        alert(handleErrorMessage(e))
      }
    }
  }
}

export default manualStore
