<template>
  <v-app>
    <v-app-bar fixed app>
      <v-spacer/>
      <HomeHeader/>
      <v-spacer/>
    </v-app-bar>
    <v-main>
      <v-container class="mb-16">
        <nuxt/>
      </v-container>
    </v-main>
    <v-bottom-navigation
      v-if="!bottomBarHidden()"
      class="bottom-navigation"
      mandatory
      fixed
      active-class="active-menu"
    >
      <nuxt-link :to="localePath('/')">
        <v-btn class="nav-btn" value="recent">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </nuxt-link>

      <nuxt-link :to="localePath('timesheets')">
        <v-btn class="nav-btn" value="timesheets">
          <v-icon>
            mdi-format-list-bulleted
          </v-icon>
        </v-btn>
      </nuxt-link>

      <nuxt-link :to="localePath('notifications')">
        <v-btn class="nav-btn" value="notifications">
          <v-icon>
            mdi-bell
          </v-icon>
        </v-btn>
      </nuxt-link>
    </v-bottom-navigation>
  </v-app>
</template>

<style>
  .bottom-navigation a {
    text-decoration: none;
    height: 100%;
  }

  .v-btn.active-menu > .v-btn__content .v-icon {
    color: #6DB249;
  }
</style>

<script>
  import Vue from 'vue'
  import VMain from 'vuetify/src/components/VMain/VMain'
  import HomeHeader from '~/components/HomeHeader'

  export default Vue.extend({
    components: {
      VMain,
      HomeHeader
    },
    data() {
      return {
        clipped: false,
        drawer: false,
        fixed: false,
        miniVariant: false,
        right: true,
        rightDrawer: false
      }
    },
    methods: {
      bottomBarHidden() {
        return document.location.pathname.includes('login');
      },
    },
  })
</script>
