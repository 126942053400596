import {
  Module
} from 'vuex'
import { format } from 'date-fns'

interface State {
  date: Date
}

const store: Module<State, State> = {
  state() {
    return {
      date: new Date
    }
  },
  getters: {
    time: (state) => {
      return state.date.getTime()
    },
    rawTime: (state) => {
      return state.date
    },
    date: (state) => {
      return format(state.date, 'yyyy-MM-dd')
    },
    dateHM: (state) => {
      return format(state.date, 'HH:mm')
    }
  },
  mutations: {
    refresh(state: State, date: Date) {
      state.date = date
    }
  },
  actions: {
  }
}

export default store
