import {
  // eslint-disable-next-line import/named
  Module
} from 'vuex'
import liff from '@line/liff'

export interface Driver {
  firstName: string
  lastName: string
}

interface State {
  user: Driver
}

const store: Module<State, State> = {
  state() {
    return {
      user: {
        firstName: '',
        lastName: ''
      }
    }
  },
  mutations: {
    setUser(state: State, user) {
      state.user = {
        firstName: user.first_name,
        lastName: user.last_name,
      }
    }
  },
  actions: {
    countup: ({ commit }) => commit('increment'),
    configuration({commit}, {user, liffId}: {user: Driver, liffId: string}) {
      commit('setUser', user)

      if (liff.id) {
        return
      }

      liff.init({ liffId }, async () => {
        if (!liff.isInClient()) {
          return
        }

        if (!liff.isLoggedIn()) {
          liff.login()
          return
        }

        const profile = await liff.getProfile()
        if (!this.$auth.loggedIn) return
        return this.$axios.$post('/me/line', {'line_id': profile.userId})
      }, (error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
    }
  }
}

export default store
